// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    firebase: {
        // apiKey: 'AIzaSyDcLzaQcm61UZwBPZ2zB8JiaGlk9IU8_bs',
        // authDomain: 'nutre-muac-dev.firebaseapp.com',
        // databaseURL: 'https://nutre-muac-dev.firebaseio.com',
        // projectId: 'nutre-muac-dev',
        // storageBucket: 'nutre-muac-dev.appspot.com',
        // messagingSenderId: '574217397429',
        // appId: '1:574217397429:web:275280ae1095780f474afc',
        // measurementId: 'G-D384Z381CL'
        apiKey: 'AIzaSyCF1McNxlTPg97s-2xh5hc2KYiLNaZXR1A',
        authDomain: 'nutre-muac-prod.firebaseapp.com',
        databaseURL: 'https://nutre-muac-prod.firebaseio.com',
        projectId: 'nutre-muac-prod',
        storageBucket: 'nutre-muac-prod.appspot.com',
        messagingSenderId: '753240829519',
        appId: "1:753240829519:web:a4087979f01aaf0098043a",
        measurementId: "G-1B1XDR2YN5"
    },
    hmr: false,
    backend: false
};
