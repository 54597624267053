import { Injectable } from '@angular/core';
import {OrganizationService} from './organization.service';
import {User} from '../model/user.model';
import {UserVerificationCodeService} from './user-verification-code.service';

@Injectable({
  providedIn: 'root'
})
export class AccountDetailsService {

  static UNICEF_SUPERVISOR = 'UNICEF';
  static DPS_SUPERVISOR = 'DPS';
  static SRS_SUPERVISOR = 'SRS';
  static SNS_SUPERVISOR = 'SNS';

  // Identify and Treat
  private CENTER_PRIMER_NIVEL = '159357';
  private INAIPI = '568324';
  private ONG = '996243';
  private FUNDATIONS = '219436';
  private CRUZ_ROJA = '728957';
  private HOSPITAL = '452631';
  private OTHERS = '346455';

  // Supervises
  private SUP_CENTER_PRIMER_NIVEL = '832673';
  private SUP_INAIPI = '762453';
  private SUP_ONG = '342957';
  private SUP_FUNDATIONS = '632804';
  private SUP_OTHERS = '128934';
  private DPS = '423439';
  private SRS = '321321';
  private SNS = '756390';
  private MINISTERIO_SALUD = '901358';
  private UNICEF = '682365';
  private SUP_CRUZ_ROJA = '475983';
  private SUP_HOSPITAL = '890672';

  user: User = new User();

  constructor(private userVerificationCodeService: UserVerificationCodeService) {
  }

  isCodeValid(supervises: boolean, organization: string, code: string) {
    if (supervises) {
      switch (organization) {
        case OrganizationService.centerPrimerNivel:
          return code === this.SUP_CENTER_PRIMER_NIVEL;
        case OrganizationService.inaipi:
          return code === this.SUP_INAIPI;
        case OrganizationService.ong:
          return code === this.SUP_ONG;
        case OrganizationService.pastoral:
          return code === this.SUP_ONG;
        case OrganizationService.fundations:
          return code === this.SUP_FUNDATIONS;
        case OrganizationService.others:
          return code === this.SUP_OTHERS;
        case OrganizationService.dps:
          return code === this.DPS;
        case OrganizationService.srs:
          return code === this.SRS;
        case OrganizationService.sns:
          return code === this.SNS;
        case OrganizationService.ministerioSalud:
          return code === this.MINISTERIO_SALUD;
        case OrganizationService.unicef:
          return code === this.UNICEF;
        case OrganizationService.cruzRoja:
          return code === this.SUP_CRUZ_ROJA;
        case OrganizationService.hospital:
          return code === this.SUP_HOSPITAL;
        default:
          return false;
      }
    }

    switch (organization) {
      case OrganizationService.centerPrimerNivel:
        return code === this.CENTER_PRIMER_NIVEL;
      case OrganizationService.inaipi:
        return code === this.INAIPI;
      case OrganizationService.ong:
        return code === this.ONG;
      case OrganizationService.fundations:
        return code === this.FUNDATIONS;
      case OrganizationService.others:
        return code === this.OTHERS;
      case OrganizationService.cruzRoja:
        return code === this.CRUZ_ROJA;
      case OrganizationService.hospital:
        return code === this.HOSPITAL;
      default:
        return false;

    }
  }

  async validateCodeWithFirebase(supervises: boolean, organization: string, code: string) {
    const verificationCodes = this.getVerificationCodeMap(supervises);
    const name = verificationCodes.get(organization);
    const userVerificationCode = await this.userVerificationCodeService.findByName(name);
    return userVerificationCode.code === code;
  }

  private getVerificationCodeMap(supervises: boolean): Map<string, string> {
    const verificationCodes = new Map();
    if (supervises) {
      verificationCodes.set(OrganizationService.centerPrimerNivel, 'SUP_CENTER_PRIMER_NIVEL');
      verificationCodes.set(OrganizationService.inaipi, 'SUP_INAIPI');
      verificationCodes.set(OrganizationService.ong, 'SUP_ONG');
      verificationCodes.set(OrganizationService.motherAndChildPastoral, 'SUP_ONG');
      verificationCodes.set(OrganizationService.fundations, 'SUP_FUNDATIONS');
      verificationCodes.set(OrganizationService.others, 'SUP_OTHERS');
      verificationCodes.set(OrganizationService.dps, 'DPS');
      verificationCodes.set(OrganizationService.srs, 'SRS');
      verificationCodes.set(OrganizationService.sns, 'SNS');
      verificationCodes.set(OrganizationService.ministerioSalud, 'MINISTERIO_SALUD');
      verificationCodes.set(OrganizationService.unicef, 'UNICEF');
      verificationCodes.set(OrganizationService.cruzRoja, 'SUP_CRUZ_ROJA');
      verificationCodes.set(OrganizationService.hospital, 'SUP_HOSPITAL');
    } else {
      verificationCodes.set(OrganizationService.centerPrimerNivel, 'CENTER_PRIMER_NIVEL');
      verificationCodes.set(OrganizationService.inaipi, 'INAIPI');
      verificationCodes.set(OrganizationService.ong, 'ONG');
      verificationCodes.set(OrganizationService.fundations, 'FUNDATIONS');
      verificationCodes.set(OrganizationService.others, 'OTHERS');
      verificationCodes.set(OrganizationService.cruzRoja, 'CRUZ_ROJA');
      verificationCodes.set(OrganizationService.hospital, 'HOSPITAL');
    }
    return verificationCodes;
  }

}
