import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {UserVerificationCodeModel} from '../model/user-verification-code.model';

@Injectable({
    providedIn: 'root'
})
export class UserVerificationCodeService {

    private collectionName = 'userVerificationCodes';

    constructor(private db: AngularFirestore) {
    }

    listAll(): Observable<UserVerificationCodeModel[]> {
        return this.db
            .collection<UserVerificationCodeModel>(this.collectionName)
            .valueChanges();
    }

    async findByName(name: string): Promise<UserVerificationCodeModel | undefined> {
        const querySnapshot = await this.db
            .collection<UserVerificationCodeModel>(
                this.collectionName,
                ref => ref
                    .where('name', '==', name)
                    .limit(1)
            )
            .get()
            .toPromise();
        if (querySnapshot.empty) {
            return undefined;
        }
        const doc = querySnapshot.docs[0];
        return doc.data() as UserVerificationCodeModel;
    }

}
